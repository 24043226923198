import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppcmsService } from "./appcms.service";
import { CacheService } from "./cache.service";

@Injectable({
  providedIn: "root",
})
export class TranslationService {

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private translateService: TranslateService
  ) {

  }

  get(key: string | string[]) {
    return this.translateService.get(key);
  }

  translate(text: string, target: string|null = null, source: string|null = null) {
    return new Promise(async (resolve, reject) => {
      let key = "translate:" + source + ":" + target + ":" + text,
        fromCache: cacheItem = await this.cache.get(key, 60 * 60 * 60);
      if (fromCache && fromCache.data) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData(
          "googletranslate",
          {
            source: source,
            target: target,
            text: text,
          },
          ["translate"]
        )
          .then((response: any) => {
            if (response.success && response.response) {
              this.cache.set(key, response.response);
              resolve(response.response);
            } else {
              reject(response);
            }
          })
          .catch(reject);
      }
    });
  }

  translateItems(items: any) {
    return new Promise((resolve, reject) => {
      let iCurrent = 0;
      items.forEach((item: any) => {
        this.translate(item.post_content)
          .then((translation: any) => {
            item.post_content = translation;

            this.translate(item.post_title)
              .then((translation: any) => {
                iCurrent++;
                item.post_title = translation;
                item.name = translation;
                if (iCurrent === items.length) {
                  resolve(items);
                }
              })
              .catch((error: any) => {
                iCurrent++;
                if (iCurrent === items.length) {
                  console.warn("finished with error", error);
                  resolve(items);
                }
              });
          })
          .catch((error: any) => {
            iCurrent++;
            this.translate(item.post_title)
              .then((translation: any) => {
                item.post_title = translation;
                if (iCurrent === items.length) {
                  resolve(items);
                }
              })
              .catch((error: any) => {
                if (iCurrent === items.length) {
                  console.warn("finished with error", error);
                  resolve(items);
                }
              });
          });
      });
    });
  }

}
