<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border container">
    <ion-item lines="none">
      <ion-button (click)="dismiss()" slot="start" fill="clear" color="dark" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>
      <ion-avatar slot="start" *ngIf="story && story.user && story.user.photo" (error)="story.user.photo=avatarUrl" (click)="viewProfile()">
        <ion-img [src]="story.user.photo"></ion-img>
      </ion-avatar>
      <ion-label *ngIf="story && story.user" (click)="viewProfile()">
        <h2 [innerHTML]="story.user.nickname || (story.user.firstname + ' ' + story.user.lastname)"></h2>
        <p *ngIf="story.timestamp_formatted" [innerHTML]="story.timestamp_formatted"></p>
      </ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>

<pipeline-reactions-stage></pipeline-reactions-stage>

<ion-content>

  <ion-refresher slot="fixed" (ionRefresh)="dismiss($event)">
    <ion-refresher-content pullingIcon="close"></ion-refresher-content>
  </ion-refresher>

  <swiper-container [pagination]="true" (swiper)="setSwiperInstance($event)" id="storySlides" *ngIf="story && story.posts" [class.postsLengthXL]="story.posts.length > 4" [class.postsLengthXXL]="story.posts.length > 8">
    
    <swiper-slide *ngFor="let post of story.posts">
      
      <div class="wrapper">
        <div class="container">

          <ion-fab *ngIf="story.user && (story.user.uid === user.uid)" vertical="top" horizontal="end" slot="fixed">
            <ion-fab-button size="small">
              <ion-icon name="settings-outline"></ion-icon>
            </ion-fab-button>
            <ion-fab-list side="bottom">
              <ion-fab-button (click)="delete(post)" icon-only color="danger">
                <ion-icon name="trash" color="white"></ion-icon>
              </ion-fab-button>
            </ion-fab-list>
          </ion-fab>

          <div class="background"></div>

          <ion-card (click)="onPostCardClick(post)" [class.videoMode]="!!post.video">
            <ion-card-header>
              <ion-card-title [innerHTML]="post.name" *ngIf="!!(post.name && post.name.length && (post.name !== 'undefined'))"></ion-card-title>
              
              <ion-thumbnail *ngIf="!!post.thumbnail">
                <ion-img [src]="post.thumbnail" (ionError)="thumbnailLoadingFailed(post)"></ion-img>
              </ion-thumbnail>

              <div class="video-wrapper" *ngIf="post.video" [innerHTML]="post.video"></div>
            </ion-card-header>
            <ion-card-content *ngIf="post.post_excerpt_formatted && !post.video">
              <p [innerHTML]="post.post_excerpt_formatted"></p>
            </ion-card-content>
          </ion-card>
  
          <ion-button (click)="read(post)" [hidden]="post.type != 'post'">
            <ion-icon name="book-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'read_now'|translate"></ion-label>
          </ion-button>
  
          <ion-button (click)="read(post)" [hidden]="post.type != 'profile'">
            <ion-icon name="person-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'view_profile'|translate"></ion-label>
          </ion-button>
  
          <ion-button (click)="read(post)" [hidden]="post.type != 'product'">
            <ion-icon name="eye-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'view_product'|translate"></ion-label>
          </ion-button>

        </div>

      </div>

    </swiper-slide>

  </swiper-container>
</ion-content>

<ion-footer *ngIf="view.emojis && config.useReactions" class="ion-no-border">
  <ion-toolbar class="ion-no-border container">
    <ion-segment class="reactions" [(ngModel)]="view.reaction" scrollable>
      <ion-segment-button *ngFor="let emoji of view.emojis" (click)="react(emoji, story)" [value]="emoji.name" icon-only>
        <ion-label>
          <span [innerHTML]="emoji.emoji"></span>
          <small *ngIf="story && story.reactions && story.reactions[emoji.name]" [innerHTML]="story.reactions[emoji.name].count"></small>
        </ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>