import { Injectable } from "@angular/core";
import { Platform } from '@ionic/angular';

import * as $ from "jquery";

@Injectable({
  providedIn: "root",
})
export class ToolsService {

  categoryIcons: any = {
    'Accessoires': 'diamond-outline',
    'Aktien': 'bar-chart-outline',
    'Autos': 'car-outline',
    'Basketball': 'basketball-outline',
    'Business & Finanzen': 'briefcase-outline',
    'Comedy': 'happy-outline',
    'Computer': 'laptop-outline',
    'Cryptowährungen': 'logo-bitcoin',
    'eBikes': 'bicycle-outline',
    'Fashion & Beauty': 'shirt-outline',
    'Fernseher': 'tv-outline',
    'Filme': 'film-outline',
    'Football': 'american-football-outline',
    'Fotografie': 'camera-outline',
    'Fussball': 'football-outline',
    'Gaming': 'game-controller-outline',
    'Garten': 'leaf-outline',
    'Gesundheit': 'body-outline',
    'Golf': 'golf-outline',
    'Hardware': 'hardware-chip-outline',
    'Immobilien': 'business-outline',
    'Körper & Geist': 'leaf-outline',
    'Küche': 'restaurant-outline',
    'Laptops': 'laptop-outline',
    'Luftverkehr': 'airplane-outline',
    'Leichtathletik': 'body-outline',
    'Marketing': 'megaphone-outline',
    'Meldungen': 'alert-circle-outline',
    'Mobilität': 'bicycle-outline',
    'Musik': 'musical-notes-outline',
    'Programmierung': 'code-outline',
    'Regionales': 'location-outline',
    'Schlafzimmer': 'bed-outline',
    'Server': 'server-outline',
    'Smartphones': 'phone-portrait-outline',
    'Sprachen': 'language-outline',
    'Sport': 'football-outline',
    'Startup': 'rocket-outline',
    'Steuern': 'cash-outline',
    'Tablets': 'tablet-portrait-outline',
    'Technik': 'save-outline',
    'Tennis': 'tennisball-outline',
    'Tipps': 'bulb-outline',
    'Uhren': 'time-outline',
    'Unser Planet': 'globe-outline',
    'Unterhaltung': 'wine-outline',
    'Unternehmensführung': 'business-outline',
    'Weltraum': 'rocket-outline',
    'Wirtschaft': 'trending-up-outline',
    'Wissen': 'book-outline',
    'Wohnen': 'home-outline',
  };
  
  videoPosterUrl: string = './assets/img/pipeline_loading.jpg';
  
  constructor(
    private platform: Platform,
  ) {

  }

  calcVirtualPostCardItemSize() {
    return 500;
  }

  capitalize(string: string) {
    return (string || '').charAt(0).toUpperCase() + (string || '').slice(1);
  }

  cleanContent(post: post, key: string = 'post_content') {
    let content = post[key];

    if(!content) {
      return '';
    }

    if(content && (typeof content === 'object') && content.hasOwnProperty('rendered')) {
      content = content.rendered;
    }

    content = this.decodeEntities(content);

    let contentElement = $("<div />").html(content);
    
    contentElement.find('img').each((index: number, element: any) => {
      element = $(element);
      element.attr('loading', 'lazy');
      if(element.attr('src') && element.attr('srcset')) {
        element.removeAttr('srcset');
      }
    });

    contentElement.find('style,form,input,button').each((index: number, element: any) => {
      $(element).remove();
    });

    contentElement.find('*').each((index: number, element: any) => {
      element = $(element);
      element.removeAttr('class').removeAttr('style');
      var data = element.data();
      if(data && data.length) {
        for(var i in data) {
          element.removeAttr("data-" + i);
        }
      }
    });

    content = contentElement[0].outerHTML;

    if(post.thumbnail && post.thumbnail.length) {
      let thumbnailSplit = post.thumbnail.split("/wp-content/"),
          thumbnailLookup = thumbnailSplit[thumbnailSplit.length - 1];
  
      thumbnailLookup = (thumbnailLookup + '')
        .replace(".jpg", "")
        .replace(".webp", "")
        .replace(".jpeg", "");
      
      let thumbnailLookupSplit = thumbnailLookup.split('-');

      if(isNaN(thumbnailLookupSplit[thumbnailLookupSplit.length-1] as any)) {
        thumbnailLookup = thumbnailLookup.replace('-' + thumbnailLookupSplit[thumbnailLookupSplit.length-1], '');
      }
  
      let selector = "img[src*='" + thumbnailLookup + "']",
          thumbnailInContent = contentElement.find(selector);
  
      if (thumbnailInContent.length) {
        thumbnailInContent.remove();
      }

    }

    content = contentElement[0].outerHTML;
    content = (content || '').replace(/\[.*?\]/g, "");
    contentElement = $(content);

    //let isVideoModeContent = this.isVideoModeContent(contentElement[0].outerHTML);

    contentElement.find('video').each((index: number, videoElement: any) => {
      videoElement = $(videoElement);
      //videoElement.prop("controls", true);
      videoElement.attr('webkit-playsinline', '');
      videoElement.attr('playsinline', '');
      videoElement.attr("preload", 'auto');
      videoElement.attr('controlsList', 'nodownload');
      videoElement.attr('poster', this.getVideoPosterUrl());
      videoElement.addClass('pipeline-video');
    });

    content = contentElement[0].outerHTML;
    return content;
  }
  
  cleanLink(link: string) {
    return (link || '').replace('https://', '').replace('http://', '').replace('www.', '');
  }

  decodeEntities(encodedString: string) {

    if(!encodedString) {
      return '';
    }

    var textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return (textArea.value || '').replace('=“', '="').replace('″]', '"]');
  }

  getCalcRandomSize(
    lastSize: number,
  ) {
    if (lastSize) {
      return 12 - lastSize;
    }
    let sizes = window.innerWidth >= 768 ? [6, 12, 4, 8] : [6, 12];
    return sizes[Math.floor(Math.random() * sizes.length)];
  }

  getCategoryIcon(categoryName: string)
  {
    return this.categoryIcons[this.decodeEntities(categoryName)];
  }

  getLinkFromString(string: string) {
    var parser = new DOMParser(),
        htmlDoc = parser.parseFromString(string, 'text/html'),
        tag = htmlDoc.getElementsByTagName('a')[0];
        
    return `${tag.getAttribute('href')}`;
  }

  getOS() {
    return this.platform.is('ios') ? 'ios' : (this.platform.is('android') ? 'android' : 'web');
  }

  getVideoFromContent(content: string) {
    let contentElement = $("<div />").html(content),
        video = contentElement.find('video').first();
    return video[0].outerHTML;
  }

  getVideoPosterUrl() {
    return this.videoPosterUrl;
  }

  groupBy(data: any[], key: string) {
    return data.reduce(function (r, a) {
      r[a[key]] = r[a[key]] || [];
      r[a[key]].push(a);
      return r;
    }, Object.create(null));
  }

  isVideoModeContent(content: string) {
    return (content.substr(0, 30).indexOf('<video') !== -1)
  }

  isDesktop() {
    return window.innerWidth >= 1024;
  }
  
  isValidURL(string: string, blAppendHttpProtocolIfMissing: boolean = true) {

    if(!!blAppendHttpProtocolIfMissing && (string.indexOf('http') !== 0) && (string.indexOf('.') !== -1)) {
      string = `http://${string}`;
    }
    
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
	    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
	    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
	    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
	    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
	    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
      
	  return !!urlPattern.test(string);
  }

  isWeb() {
    return this.platform.is('mobileweb') || this.platform.is('desktop') || (document.URL || '').indexOf('de.app.tie') >= 0 || (document.URL || '').indexOf('web.pipeline.page') >= 0;
  }

  lcfirst(string: string) {
    return (string || '').charAt(0).toLowerCase() + (string || '').slice(1);
  }

  ltrim(string: string, char: string = '') {
    if (!char) {
      char = '\\s';
    }
    return string.replace(new RegExp("^[" + char + "]*"), '');
  }

  rtrim(string: string, char: string = '') {
    if (!char) {
      char = '\\s';
    }
    return string.replace(new RegExp("[" + char + "]*$"), '');
  }
  
  shuffle(a: any) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  stripHtml(html: string)
  {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = (html || '').replace(/<[^>]*>?/gm, '');

    html = $(tmp).text().replace('/p> ', '');
    return html;
  }
  
  titleCase(string: string) {
    var splitStr = string.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }

  truncateBySentences(text: string, sentCount: number = 5, moreText: string = "") {
    var sentences = text.match(/[^\.!\?]+[\.!\?]+/g);
    if (sentences) {
      if (sentences.length >= sentCount && sentences.length > sentCount) {
        return sentences.slice(0, sentCount).join('') + moreText;
      }
    }
    return text;
  }
  
  uniqueFunction(value: any, index: number, array: any) {
    return array.indexOf(value) === index;
  }

  unique(a: any) {
    return a.filter(this.uniqueFunction);
  }

  validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateUrl(url: string, blAppendHttpProtocolIfMissing: boolean = true) {
    
    if(!!blAppendHttpProtocolIfMissing && (url.indexOf('http') !== 0) && (url.indexOf('.') !== -1)) {
      url = `http://${url}`;
    }

    return {
      success: this.isValidURL(url),
      url: url,
    }; 
  }
  
  wrapURL(text: string, target: string = '_blank') { 
    
    if(!!text && (text.indexOf('<a href') !== -1)) {
      return text;
    }

    let urlPattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\z`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    let result = text.replace(urlPattern, function(url) { 
        return `<a href="${url.trim()}" ${!!target ? `target="${target}"` : target} class="wrapped-a-tag">${url.trim()}</a>`; 
    });
    return result; 
  } 
  
}