//export var apiUrl: string = 'https://staging.pipeline.page/api';
export var apiUrl: string = 'https://app.pipeline.page/api'; 

export var hostUrl: string = 'https://app.pipeline.page'; 
export var proxyPrefix: string = apiUrl + '/searchengine/proxy.json?format=json&url=';

export var pipelineAppConfig: pipelineAppConfig = {
    allowUserCreateChat: true,
    allowUserCreateChatGroup: false,
    allowUserEditPostSettings: false,
    allowUserForgotPassword: true,
    allowUserLogin: true,
    allowUserPostContent: false,
    allowUserPostContentAsCreator: true,
    allowUserPostContentAsGuest: false,
    allowUserPostContentAsUser: false,
    allowUserRegister: true,
    allowUserTranslatePost: false,
    allowUserWriteChatMessage: true,
    appDescription: 'Dein persönliches Magazin',
    appId: 'de.app.tie',
    appName: 'pipeline',
    appPackageName: 'pipeline-ionic-app',
    appSlogan: 'Dein persönliches Magazin',
    appVersionId: 100905,
    appVersionName: '0.9.5',
    environments: {
        api: {
            production: 'https://app.pipeline.page/api',
        },
        app: {
            production: 'https://app.pipeline.page',
        }
    },
    filterDiscoverPostsByContentHostUrl: false,
    filterHomePostsByContentHostUrl: true,
    filterLocalPostsByContentHostUrl: false,
    filterPeopleByContentHostUrl: true,
    filterSearchResultsByContentHostUrl: true,
    filterUserByTeamUids: false,
    includeRatingsInFeed: false,
    includeReactionsInFeed: true,
    includeRelatedArticlesInFeed: false,
    isWhitelabel: false,
    links: {
        imprint: 'https://pipeline.page/de/impressum',
        privacy_policy: 'https://pipeline.page/de/datenschutz',
        terms_of_use: 'https://pipeline.page/de/agb',
    },
    maxPostAge: 365,
    menuSideDesktop: 'start',
    menuSideMobile: 'start',
    multiLanguageSupport: true,
    multiUser: true,
    orderBy: {
        discover: 'post_date desc',
        home: 'post_date desc',
        local: 'post_date desc',
        people: 'auto',
        shop: 'auto',
        showroom: 'post_date desc',
    },
    postsVipFactor: 0,
    pushAppId: 'cd36525a-889e-4aad-89d5-0d9d127b7df5',
    routes: {
        discover: '/tabs/discover',
        home: '/tabs/home',
        local: '/tabs/local',
        people: '/tabs/people',
        shop: '/tabs/shop',
    },
    showAccountBecomeCreator: true,
    showAccountClearCaches: true,
    showAccountSecurity: true,
    showAppearanceColorSchemes: true,
    showAppearanceFontsSelector: true,
    showAppearanceFontSizeSelector: true,
    showAppearancePreferedImageSize: true,
    showAppearanceTabs: true,
    showCheckoutPage: false,
    showFeedBottomShadow: true,
    showFooterAppVersion: true,
    showFooterBottomText: true,
    showJobPostsInFeeds: true,
    showJobPostsInSearchResults: false,
    showHeaderLargeTitle: true,
    showHeaderMenuButton: false,
    showHeaderToolbarLogo: true,
    showHeaderToolbarTitle: false,
    showLocalSourcesSuggestions: false,
    showLoginPage: false,
    showLoginTopLogo: true,
    showMenuGlobalSearch: false,
    showMenuHeaderLogo: true,
    showMenuHeaderTitle: false,
    showMenuIfLoggedOut: false,
    showMenuPagesInApp: true,
    showMenuPagesInWeb: true,
    showPodcastsSuggestions: true,
    showPodcastsSuggestionsInFeed: true,
    showPostAuthorCard: false,
    showPostCardAdminFab: false,
    showPostCardExcerpt: true,
    showPostCardHostLabel: true,
    showPostCardOptions: true,
    showPostCardVerifiedBadges: true,
    showPostPagination: false,
    showPostReadInAppCard: true,
    showPostRelatedArticles: true,
    showProfileDisplayName: true,
    showProfileEvents: false,
    showProfileFriends: true,
    showProfileSocialInformation: true,
    showProfileSocialInformationLabels: false,
    showProfileUserGroup: false,
    showProfileUserGroupAsPrimaryLabel: false,
    showProfileUsername: true,
    showSalut: true,
    showSettingsLegalLinks: true,
    showSettingsLinks: true,
    showShopCategories: true,
    showShopNewestProducts: true,
    showShopRelatedProductsOnDetailPage: true,
    showShopSaleProducts: true,
    showShopSources: true,
    showShopSuggestedProducts: true,
    showShopTrendingProducts: true,
    showSuggestedProfilesOnFeedPage: true,
    showSuggestedProfilesOnHomePage: true,
    showSuggestedProfilesOnLocalPage: true,
    showTabBar: true,
    showTabBarAsDock: true,
    showTabBarInMenuOnApp: true,
    showTabBarInMenuOnWeb: true,
    showTabBarLabels: false,
    showTabMenu: true,
    translucentFooters: true,
    translucentHeaders: true,
    useAbonnements: false,
    useAdsExtension: true,
    useAppearance: true,
    useAppleWatchExtension: true,
    useArchive: false,
    useAuthExtension: false,
    useAvatars: true,
    useBionicReadingExtension: true,
    useCalendar: false,
    useCategories: true,
    useCategoriesOnDiscoverPage: true,
    useCategoriesOnFeedPages: true,
    useCategoriesOnHomePage: false,
    useCategoriesOnLocalPage: false,
    useCategoriesOnSearchPage: true,
    useChatExtension: false,
    useCommentsExtension: true,
    useCompaniesExtension: false,
    useComplexityExtension: true,
    useCookiesAlertInApp: false,
    useCookiesAlertInWebApp: true,
    useCouponsExtension: false,
    useDatingExtension: false,
    useDeathAnnouncementsExtension: false,
    useDiscover: true,
    useDocumentsExtension: false,
    useEventsList: false,
    useExperiments: false,
    useFeedback: true,
    useFeeds: false,
    useFiltersExtension: false,
    useFollow: true,
    useGoogleMapsExtension: true,
    useGroups: false,
    useHeadlineProfileAvatar: true,
    useHome: true,
    useInbox: true,
    useInterests: true,
    useIntro: true,
    useIntroCards: true,
    useIntroPeople: true,
    useIntroReadingSettings: true,
    useInviteFriends: true,
    useJobPosts: false,
    useJobTitlesExtension: true,
    useLocal: true,
    useLoomaExtension: false,
    useMarketplaceExtension: false,
    useMediaExtension: false,
    useMoviesExtension: false,
    useOrderByFilters: true,
    usePayPalExtension: false,
    usePeople: true,
    usePointsSystem: true,
    usePostPaywallExtension: false,
    usePostsAdmin: false,
    useProfile: true,
    useProfileDisplayNames: true,
    useProfileTitleExtension: false,
    useProfileUsernames: true,
    usePushNotifications: true,
    usePushNotificationsChannels: true,
    useReactions: true,
    useRegionSearch: true,
    useRegionSearchSuggestions: false,
    useReportingExtension: true,
    useSearch: true,
    useSettings: true,
    useSharingExtension: true,
    useShop: true,
    useShopAccount: false,
    useShopCategories: true,
    useShopInternalPaymentProcess: false,
    useShortcuts: false,
    useShowroom: false,
    useSignInWithApple: false,
    useSignInWithFacebook: false,
    useSignInWithGoogle: false,
    useSimplyLocalExtension: false,
    useStories: true,
    useStoriesCameraExtension: false,
    useStoriesOnDiscoverPage: true,
    useStoriesOnHomePage: false,
    useStoriesOnLocalPage: false,
    useStoriesOnPeoplePage: true,
    useTeamsExtension: true,
    useTesting: true,
    useThemes: true,
    useThreeDeeTouch: false,
    useTransparencyExtension: false,
    useTvExtension: true,
    useVideosExtension: true,
    useWallet: false,
    useWeclappExtension: false,
    useWeloveWalletExtension: false,
}